.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiBox-root {
  padding: 0px;
}

.MuiToolbar-gutters {
  padding-left: 4px;
}

.MuiTypography-root {
  font-family: "Nunito Sans";
}

.MuiTextField-root {
  font-family: "Nunito Sans";
}

.MuiButton-contained {
  font-family: "Nunito Sans";
  font-size: 15px;
  height: 28px;
  box-shadow: none;
}

.MuiListItemIcon-root {
  min-width: 30px;
  padding-bottom: 0px;
}

.MuiAutocomplete-popper {
  padding-top: 4px;
  padding-bottom: 4px;
}

.MuiAutocomplete-root {
  font-family: "Nunito Sans";
}

.MuiAutocomplete-listbox {
  padding-left: 0px;
  margin-left: 0px;
  overflow-x: hidden;
}

.MuiAutocomplete-option {
  padding-left: 0px;
  margin-left: 0px;
}

.MuiAutocomplete-inputRoot {
  padding-left: 0px;
}

.dashboardIcon {
  color: #8f949c;
  width: 28px;
  height: 28px;
}

.nestedMenuItem {
  padding-left: 8px;
}

.nestedMenuItem:hover {
  padding-left: 8px;
  background-color: #e6f3f9;
  color: #2a6ebb;
}

.nestedMenuItemSelect {
  padding-left: 16px;
}

.nestedMenuItemSelect:hover {
  padding-left: 16px;
  background-color: #e6f3f9;
  color: #2a6ebb;
}

.nestedMenuItemDivider {
  padding-left: 8px;
  padding-bottom: 8px;
}

.nestedMenuItemDivider:hover {
  padding-left: 8px;
  padding-bottom: 8px;
  background-color: #e6f3f9;
  color: #2a6ebb;
}

.MuiTreeItem-content:hover {
  background-color: inherit;
}
.MuiTreeItem-content.Mui-selected {
  background-color: inherit;
}
.MuiTreeItem-content.Mui-selected:hover {
  background-color: inherit;
}
.MuiTreeItem-content.Mui-selected.Mui-focused {
  background-color: inherit;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: #e6f3f9;
}
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
  background-color: #e6f3f9;
}

.esri-view .esri-view-surface:focus::after {
  outline-color: #2a6ebb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
